import React from "react";
import { useConfig } from "wagmi";
import type { Chain } from "wagmi/chains";
import { provideRainbowKitChains } from "./chains";

export interface RainbowKitChain extends Chain {
  iconUrl?: string | (() => Promise<string>) | null;
  iconBackground?: string;
}

export const useRainbowKitChains = () => {
  const { chains } = useConfig();
  const _chains = React.useMemo(
    () =>
      provideRainbowKitChains(
        chains as [RainbowKitChain, ...RainbowKitChain[]]
      ),
    [chains]
  );

  return _chains;
};
