import React from "react";
import Button, { type Props } from "@components/Button/Button";
import { NavBarStateEnums, useNavStore } from "@layouts/Navigation/store";
import { useDisconnect } from "wagmi";
import { useAccount } from "@hooks/auth";
import { withProviders } from "@providers/hoc";
import { cn } from "tailwind-config/utils";

export const DisconnectButtonRaw: React.FC<Props<"button">> = ({
  className,
  ...rest
}) => {
  const { address } = useAccount();
  const { disconnect } = useDisconnect();
  const [_, setState] = useNavStore();
  const handleClick = async () => {
    disconnect();
    await fetch(`${import.meta.env.PUBLIC_SERVER_URL}/auth/logout`, {
      credentials: "include",
      method: "GET",
      mode: "no-cors",
    });
    setState(NavBarStateEnums.CLOSED);
  };

  return (
    <Button
      as="button"
      onClick={handleClick}
      {...rest}
      variant="transparent"
      disabled={!address}
      icon="logout"
      className={className}
    >
      Disconnect
    </Button>
  );
};

export const DisconnectButton = withProviders(DisconnectButtonRaw);

export default withProviders(DisconnectButtonRaw);
