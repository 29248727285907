import * as React from "react";
import { withProviders } from "@providers/hoc";
import Button from "@components/Button/Button";
import { NavBarStateEnums, useNavStore } from "../store";
import { notify, toast } from "@utils/notifications";
import { useAccountEffect } from "wagmi";
import {
  useWalletConnectors,
  type WalletConnector,
} from "@components/Wallet/useWalletConnectors";
import { groupBy } from "@components/Wallet/utils/groupBy";
import { QRCode } from "@components/QRCode";
import { UserRejectedRequestError } from "viem";
import { useAccount, useDisconnect } from "wagmi";
import { isIOS, isLargeIOS, isMobile } from "@components/Wallet/utils/isMobile";

import { Buffer } from "buffer";
import { cn } from "tailwind-config/utils";
import { getPlatform, PlatformType } from "@components/Wallet/utils/platforms";
import { BrowserType, getBrowser } from "@components/Wallet/utils/browsers";

window.global = window.global ?? window;
window.Buffer = window.Buffer ?? Buffer;
window.process = window.process ?? { env: {} }; // Minimal process polyfill

export enum WalletStep {
  None = "NONE",
  LearnCompact = "LEARN_COMPACT",
  Get = "GET",
  Connect = "CONNECT",
  DownloadOptions = "DOWNLOAD_OPTIONS",
  Download = "DOWNLOAD",
  InstructionsMobile = "INSTRUCTIONS_MOBILE",
  InstructionsDesktop = "INSTRUCTIONS_DESKTOP",
  InstructionsExtension = "INSTRUCTIONS_EXTENSION",
}

const getBrowserSrc = (): string => {
  const browser = getBrowser();
  switch (browser) {
    case BrowserType.Arc:
      return "/icons/Arc.svg";
    case BrowserType.Brave:
      return "/icons/Brave.svg";
    case BrowserType.Chrome:
      return "/icons/Chrome.svg";
    case BrowserType.Edge:
      return "/icons/Edge.svg";
    case BrowserType.Firefox:
      return "/icons/Firefox.svg";
    case BrowserType.Opera:
      return "/icons/Opera.svg";
    case BrowserType.Safari:
      return "/icons/Safari.svg";
    default:
      return "/icons/Browser.svg";
  }
};

// const preloadBrowserIcon = () => loadImages(getBrowserSrc);

const getPlatformSrc = (): string => {
  const platform = getPlatform();
  switch (platform) {
    case PlatformType.Windows:
      return "/icons/Windows.svg";
    case PlatformType.MacOS:
      return "/icons/Macos.svg";
    case PlatformType.Linux:
      return "/icons/Linux.svg";
    default:
      return "/icons/Linux.svg";
  }
};

function WalletIcon({
  url,
  name,
  className,
}: {
  url: string;
  name: string;
  className?: string;
}) {
  return (
    <img alt={name} src={url} className={cn("h-8 rounded-md", className)} />
  );
}

function WalletOption({
  connector,
  onClick,
}: {
  connector: WalletConnector;
  onClick: () => void;
}) {
  const [ready, setReady] = React.useState<boolean>(false);
  const mobile = isMobile();

  React.useEffect(() => {
    (async () => {
      if (mobile) {
        setReady(true);
        return;
      }
      const provider = await connector.getProvider();
      setReady(!!provider);
    })();
  }, [connector]);

  if (mobile && !connector.ready) return null;

  const content = (
    <>
      <div>
        <WalletIcon
          url={
            (connector.icon ?? `/walletIcons/${connector.iconUrl}`) as string
          }
          name={connector.name}
        />
      </div>
      <div>{connector.name}</div>
    </>
  );

  return (
    <Button
      variant="outlined"
      size="xl"
      disabled={!!ready}
      onClick={onClick}
      className="text-white space-x-4 w-full"
    >
      {content}
    </Button>
  );
}

function Downloads({ connector }: { connector: WalletConnector }) {
  const hasMobileCompanionApp = !!connector.downloadUrls?.qrCode;
  const hasExtension = !!connector.extensionDownloadUrl;
  const hasDesktopApp = !!connector.desktopDownloadUrl;
  const hasMobile = !!connector.mobileDownloadUrl;
  const hasMobileAndExtension = hasMobileCompanionApp && hasExtension;
  const hasMobileAndDesktop = hasMobileCompanionApp && hasDesktopApp;

  return (
    <>
      {hasMobile && (
        <Button
          variant="outlined"
          size="md"
          onClick={() => {
            window.open(connector.mobileDownloadUrl, "_blank");
          }}
          className="text-white space-x-4 w-full"
        >
          <img src={connector.iconUrl as string} className="h-8 rounded-md" />
          Get {connector.name} for Mobile
        </Button>
      )}
      {hasExtension && (
        <Button
          variant="outlined"
          size="md"
          onClick={() => {
            window.open(connector.extensionDownloadUrl, "_blank");
          }}
          className="text-white space-x-4 w-full"
        >
          <img src={getBrowserSrc()} className="h-8 rounded-md" />
          <span>Get Extension for {connector.name}</span>
        </Button>
      )}
      {hasDesktopApp && (
        <Button
          variant="outlined"
          size="md"
          onClick={() => {
            window.open(connector.desktopDownloadUrl, "_blank");
          }}
          className="text-white space-x-4 w-full"
        >
          <img src={connector.iconUrl as string} className="h-8 rounded-md" />
          Get {connector.name} for Desktop
        </Button>
      )}
    </>
  );
}

function ConnectLoading({
  connector,
  children,
}: {
  connector: WalletConnector;
  children?: React.ReactNode;
}) {
  return (
    <div className="text-white flex flex-col gap-4 justify-center h-full">
      <div className="abs-wrapper place-content-center grow">
        <WalletIcon
          url={
            (connector.icon ?? `/walletIcons/${connector.iconUrl}`) as string
          }
          name={connector.name}
          className="abs-elem self-center place-self-center h-32 md:h-8 "
        />
        <div className="animate-spin place-self-center rounded-full h-56 w-56 md:h-16 md:w-16 border-b-2 border-white mx-auto abs-elem"></div>
      </div>
      {children}
    </div>
  );
}

function WalletWindow({
  connector,
  onBackToMain,
}: {
  connector: WalletConnector;
  onBackToMain: () => void;
}) {
  const [nav, setNav] = useNavStore();
  const [downloadDetails, setDownloadDetails] = React.useState(false);
  const [details, setDetails] = React.useState<{
    ready: boolean;
    desktopUri: string | undefined;
    qrCodeUri: string | undefined;
    extensionDownloadUrl: string | undefined;
  } | null>(null);

  React.useEffect(() => {
    (async () => {
      const provider = await connector.getProvider();
      const desktopUri = await connector.getDesktopUri?.();
      const qrCodeUri = await connector.getQrCodeUri?.();
      setDetails({
        ready: !!provider,
        desktopUri,
        qrCodeUri,
        extensionDownloadUrl: connector.extensionDownloadUrl,
      });
    })();
  }, [connector]);

  React.useEffect(() => {
    if (nav !== NavBarStateEnums.WALLET_MODAL) {
      details && setDetails(null);
      downloadDetails && setDownloadDetails(false);
    }
  }, [nav, details, setDownloadDetails, downloadDetails, setDetails]);

  const hasInstalled = !details?.extensionDownloadUrl;
  const hasMobileCompanionApp = !!connector.downloadUrls?.qrCode;
  const providerIsReady = details?.ready;

  if (!providerIsReady) {
    return (
      <ConnectLoading connector={connector}>
        <h2 className="text-center">{`Loading ${connector.name}...`}</h2>
      </ConnectLoading>
    );
  }

  if (connector.ready && hasInstalled) {
    return (
      <ConnectLoading connector={connector}>
        <div className="flex flex-col gap-1 justify-center">
          <p className="font-semibold mx-auto text-grey-light">
            Opening {connector.name}...
          </p>
          <p className="text-sm mx-auto text-grey-light">
            Confirm connection in the extension
          </p>
        </div>
      </ConnectLoading>
    );
  }

  if (hasMobileCompanionApp) {
    if (downloadDetails) {
      return (
        <div className="text-white flex flex-col space-y-4">
          <Downloads connector={connector} />
          <Button
            variant="transparent"
            size="sm"
            onClick={() => {
              setDownloadDetails(false);
            }}
            className="w-fit mx-auto"
          >
            Back
          </Button>
        </div>
      );
    }
    return (
      <div className="text-white flex flex-col space-y-8">
        <div className="bg-white p-4 rounded-lg w-fit mx-auto">
          {!!details.qrCodeUri && <QRCode uri={details.qrCodeUri} />}
        </div>
        {
          <div className="flex flex-row justify-between">
            <p className="grow">{`Don't have ${connector.name}?`}</p>
            <Button
              variant="outlined"
              size="xs"
              onClick={() => {
                setDownloadDetails(true);
              }}
              className="text-white space-x-4 w-fit"
            >
              Get
            </Button>
          </div>
        }
        <Button
          variant="transparent"
          size="sm"
          onClick={() => {
            onBackToMain();
          }}
          className="w-fit mx-auto"
        >
          Back
        </Button>
      </div>
    );
  }

  if (!hasMobileCompanionApp) {
    return (
      <div className="text-white flex flex-col space-y-4">
        <Downloads connector={connector} />
        <Button
          variant="transparent"
          size="sm"
          onClick={() => {
            onBackToMain();
          }}
          className="w-fit mx-auto"
        >
          Back
        </Button>
      </div>
    );
  }

  return null;
}

export function WalletOptionsContainer({ className }: { className?: string }) {
  const [nav, setNav] = useNavStore();
  const [selectedWallet, setSelectedWallet] =
    React.useState<WalletConnector | null>();

  const { disconnect } = useDisconnect();
  const { isConnecting } = useAccount();

  useAccountEffect({
    onConnect({ connector }) {
      toast({
        text: `Successfully connected to ${connector.name}.`,
        title: "Connected Successfully!",
        icon: "success",
      });
      setNav(NavBarStateEnums.CLOSED);
    },
    onDisconnect() {
      toast({
        text: `Successfully disconnected from Jutsu World.`,
        title: "Disconnected Successfully!",
        icon: "success",
      });
      setNav(NavBarStateEnums.CLOSED);
    },
  });

  const mergeEIP6963WithRkConnectors = true;

  const isLargeIos = isLargeIOS();

  const wallets = useWalletConnectors(mergeEIP6963WithRkConnectors)
    .filter((wallet) => wallet.ready || !!wallet.extensionDownloadUrl)
    .filter((wallet) => {
      return isLargeIos ? !!wallet?.downloadUrls?.safari : true;
    })
    .sort((a, b) => a.groupIndex - b.groupIndex);

  const groupedWallets = groupBy(wallets, (wallet) => wallet.groupName);

  React.useEffect(() => {
    if (nav !== NavBarStateEnums.WALLET_MODAL) {
      selectedWallet && setSelectedWallet(null);
      // isConnecting && disconnect();
    }
  }, [nav, disconnect, isConnecting]);

  const connectToWallet = (wallet: WalletConnector) => {
    if (wallet.ready) {
      wallet?.connect?.()?.catch((e) => {
        if (e instanceof UserRejectedRequestError) {
          setSelectedWallet(null);
          return;
        }

        if (e.name === undefined && e.code === -32002) {
          toast({
            title: `Extension for ${wallet.name} is already open.`,
            text: `Please confirm the connection in the extension.`,
            icon: "info",
          });
          return;
        }

        notify({
          text: e.message,
          title: "error",
        });
      });
    }
  };

  const selectWallet = async (wallet: WalletConnector) => {
    // We still want to get the latest wallet id to show connected
    // green badge on our custom WalletButton API
    // addLatestWalletId(wallet.id);

    // This ensures that we listen to the provider.once("display_uri")
    // before connecting to the wallet

    connectToWallet(wallet);
    setSelectedWallet(wallet);
    // setSelectedOptionId(wallet.id);
  };

  if (!wallets.length) {
    return (
      <div className="text-white flex flex-col gap-4 justify-center border-2 border-white rounded-lg p-4">
        <h2 className="text-center">No wallets available</h2>
        <p className="text-center text-grey-light">
          Please install a wallet to continue
        </p>
      </div>
    );
  }

  if (isMobile()) {
    const ios = isIOS();

    if (selectedWallet) {
      return (
        <ConnectLoading connector={selectedWallet}>
          <div className="flex flex-row justify-between">
            <p className="grow">{`Don't have ${selectedWallet.name}?`}</p>
            <Button
              variant="outlined"
              size="xs"
              onClick={() => {
                const { downloadUrls } = selectedWallet;
                const downloadUrl =
                  (ios ? downloadUrls?.ios : downloadUrls?.android) ||
                  downloadUrls?.mobile;
                window.open(downloadUrl, "_blank");
              }}
              className="text-white space-x-4 w-fit"
            >
              Get
            </Button>
          </div>
        </ConnectLoading>
      );
    }

    return (
      <div className="flex flex-col space-y-3 items-center h-full justify-center">
        {wallets
          .filter((wallet) => wallet.ready)
          .map((wallet) => {
            return (
              <WalletOption
                key={wallet.id}
                connector={wallet}
                onClick={async () => {
                  const { id, getMobileUri, downloadUrls } = wallet;

                  const downloadUrl =
                    (ios ? downloadUrls?.ios : downloadUrls?.android) ||
                    downloadUrls?.mobile;

                  const onMobileUri = async () => {
                    const mobileUri = await getMobileUri?.();

                    if (!mobileUri) return;

                    // if (mobileUri) {
                    //   setWalletConnectDeepLink({ mobileUri, name });
                    // }

                    const timeout = setTimeout(function () {
                      if (!downloadUrl) return;
                      console.log("Opening downloadUrl", downloadUrl);
                      const link = document.createElement("a");
                      link.href = downloadUrl;
                      link.target = "_blank";
                      link.rel = "noreferrer noopener";
                      link.click();
                    }, 100);

                    console.log("Opening mobileUri", mobileUri);
                    const link = document.createElement("a");
                    link.href = mobileUri;
                    link.target = "_blank";
                    link.rel = "noreferrer noopener";
                    link.click();

                    clearTimeout(timeout);

                    // if (mobileUri.startsWith("http")) {
                    //   // Workaround for https://github.com/rainbow-me/rainbowkit/issues/524.
                    //   // Using 'window.open' causes issues on iOS in non-Safari browsers and
                    //   // WebViews where a blank tab is left behind after connecting.
                    //   // This is especially bad in some WebView scenarios (e.g. following a
                    //   // link from Twitter) where the user doesn't have any mechanism for
                    //   // closing the blank tab.
                    //   // For whatever reason, links with a target of "_blank" don't suffer
                    //   // from this problem, and programmatically clicking a detached link
                    //   // element with the same attributes also avoids the issue.

                    //   const timeout = setTimeout(function () {
                    //     if (!downloadUrl) return;
                    //     console.log("Opening downloadUrl", downloadUrl);
                    //     const link = document.createElement("a");
                    //     link.href = downloadUrl;
                    //     link.target = "_blank";
                    //     link.rel = "noreferrer noopener";
                    //     link.click();
                    //   }, 100);

                    //   console.log("Opening mobileUri", mobileUri);
                    //   const link = document.createElement("a");
                    //   link.href = mobileUri;
                    //   link.target = "_blank";
                    //   link.rel = "noreferrer noopener";
                    //   link.click();

                    //   clearTimeout(timeout);
                    // } else {
                    //   const timeout = setTimeout(function () {
                    //     if (!downloadUrl) return;
                    //     console.log("Opening downloadUrl Lower", downloadUrl);
                    //     window.location.href = downloadUrl;
                    //   }, 100);
                    //   console.log("Opening mobileUri lower", mobileUri);
                    //   try {
                    //     window.location.href = mobileUri;
                    //   } catch (error) {
                    //     console.log("error", error);
                    //   }
                    //   // clearTimeout(timeout);
                    // }
                  };

                  if (id !== "walletConnect") onMobileUri();

                  selectWallet(wallet);
                }}
              />
            );
          })}
      </div>
    );
  }

  if (selectedWallet) {
    return (
      <WalletWindow
        connector={selectedWallet}
        onBackToMain={() => setSelectedWallet(null)}
      />
    );
  }

  return (
    <>
      {Object.entries(groupedWallets).map(
        ([groupName, wallets], index) =>
          wallets.length > 0 && (
            <React.Fragment key={index}>
              {groupName ? (
                <div className="mb-2 mt-4 mx-1.5 ">
                  <span
                    className={`text-sm font-bold ${
                      groupName === "Installed"
                        ? "text-white"
                        : "text-grey-light"
                    }`}
                  >
                    {groupName.toUpperCase()}
                  </span>
                </div>
              ) : null}
              <div className="flex flex-col space-y-3">
                {wallets.map((wallet) => (
                  <WalletOption
                    key={wallet.id}
                    connector={wallet}
                    onClick={async () => {
                      selectWallet(wallet);
                    }}
                  />
                ))}
              </div>
            </React.Fragment>
          )
      )}
    </>
  );
}

export const WalletOptions = withProviders(WalletOptionsContainer);
